var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "partDetailedSuppliersPrices"
  }, [_c('v-card-title', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('DetailedPricesBySuppliers')) + " ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "small": "",
      "color": "error"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times ")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "col-md-6 col-xl-4 px-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.suppliers,
      "item-text": "name",
      "item-value": "name",
      "multiple": "",
      "dense": "",
      "label": _vm.$t('Suppliers'),
      "menu-props": {
        bottom: true
      },
      "clearable": "",
      "auto": ""
    },
    on: {
      "input": _vm.updateFilter
    },
    model: {
      value: _vm.suppliersFilter,
      callback: function ($$v) {
        _vm.suppliersFilter = $$v;
      },
      expression: "suppliersFilter"
    }
  })], 1), _c('v-col', {
    staticClass: "col-md-6 col-xl-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.technologies,
      "item-text": "text",
      "item-value": "value",
      "multiple": "",
      "dense": "",
      "label": _vm.$t('Technologies'),
      "menu-props": {
        bottom: true
      },
      "clearable": "",
      "auto": ""
    },
    on: {
      "input": _vm.updateFilter
    },
    model: {
      value: _vm.technologiesFilter,
      callback: function ($$v) {
        _vm.technologiesFilter = $$v;
      },
      expression: "technologiesFilter"
    }
  })], 1), _c('v-col', {
    staticClass: "col-md-6 col-xl-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.materials,
      "item-text": "text",
      "item-value": "value",
      "multiple": "",
      "dense": "",
      "label": _vm.$t('Materials'),
      "menu-props": {
        bottom: true
      },
      "clearable": "",
      "auto": ""
    },
    on: {
      "input": _vm.updateFilter
    },
    model: {
      value: _vm.materialsFilter,
      callback: function ($$v) {
        _vm.materialsFilter = $$v;
      },
      expression: "materialsFilter"
    }
  })], 1), _c('v-col', {
    staticClass: "col-md-6 col-xl-4 px-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-slider', {
    staticClass: "align-center",
    attrs: {
      "max": 100,
      "min": 0,
      "label": _vm.$t('Margin'),
      "thumb-label": "always",
      "append-icon": "fas fa-percent"
    },
    on: {
      "input": _vm.updatePartPrices
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('v-text-field', {
          staticClass: "mt-0 pt-0",
          staticStyle: {
            "width": "60px"
          },
          attrs: {
            "max": 100,
            "min": 0,
            "type": "number"
          },
          on: {
            "change": _vm.updatePartPrices
          },
          model: {
            value: _vm.margin,
            callback: function ($$v) {
              _vm.margin = $$v;
            },
            expression: "margin"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.margin,
      callback: function ($$v) {
        _vm.margin = $$v;
      },
      expression: "margin"
    }
  })], 1), _c('v-col', {
    staticClass: "col-md-6 col-xl-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('HideIncompatiblePrinters')
    },
    on: {
      "change": _vm.updateFilter
    },
    model: {
      value: _vm.hideIncompatiblePrinters,
      callback: function ($$v) {
        _vm.hideIncompatiblePrinters = $$v;
      },
      expression: "hideIncompatiblePrinters"
    }
  })], 1)], 1), _vm.filteredPartPrices.length > 0 ? _c('v-row', _vm._l(_vm.filteredPartPrices, function (priceObj, index) {
    return _c('v-col', {
      key: index,
      staticClass: "col-md-6 col-xl-4",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": "",
        "color": "info"
      }
    }, [_vm._v(" fas fa-city ")]), _c('span', [_vm._v(" " + _vm._s(priceObj.supplier.name) + " ")]), _c('v-spacer'), _c('span', [_vm._v(" " + _vm._s(_vm.$t(priceObj.technology)) + " - " + _vm._s(_vm.$t(priceObj.material)) + " ")]), !_vm.hasPrinterLargeEnough(priceObj.supplier._id, priceObj.technology) ? _c('v-tooltip', {
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on;
          return [_c('v-icon', _vm._g({
            staticClass: "ml-4",
            attrs: {
              "color": "warning"
            }
          }, on), [_vm._v(" fas fa-exclamation-triangle ")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('MightNotFit', {
      technology: _vm.$t(priceObj.technology)
    })) + " ")])]) : _vm._e()], 1), _c('v-card-text', [_c('v-data-table', {
      attrs: {
        "items": priceObj.prices,
        "hide-default-header": true,
        "hide-default-footer": true
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function () {
          return [_c('thead', [_c('tr', [_c('th', {
            attrs: {
              "scope": "col"
            }
          }, [_vm._v(" " + _vm._s(_vm.$t('Quantity')) + " ")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "scope": "col"
            }
          }, [_vm._v(" " + _vm._s(_vm.$t('UnitaryPrice')) + " ")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "scope": "col"
            }
          }, [_vm._v(" " + _vm._s(_vm.$t('TotalPrice')) + " ")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "scope": "col"
            }
          }, [_vm._v(" " + _vm._s(_vm.$t('TotalVolume')) + " ")]), _c('th', {
            staticClass: "text-center",
            attrs: {
              "scope": "col"
            }
          }, [_vm._v(" " + _vm._s(_vm.$t('VolumePrice')) + " ")])])])];
        },
        proxy: true
      }, {
        key: `body.prepend`,
        fn: function () {
          return [_c('tr', [_c('td', [_c('v-text-field', {
            staticClass: "customQuantityField",
            attrs: {
              "type": "number",
              "min": 0
            },
            on: {
              "input": _vm.updatePartPrices
            },
            model: {
              value: _vm.customQuantity,
              callback: function ($$v) {
                _vm.customQuantity = $$v;
              },
              expression: "customQuantity"
            }
          })], 1), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(priceObj.customPrice.unitaryPrice) + " € ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(priceObj.customPrice.totalPrice) + " € ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(priceObj.customPrice.totalVolume) + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(priceObj.customPrice.volumePrice) + " €/cm³ ")])])];
        },
        proxy: true
      }, {
        key: "item",
        fn: function (_ref2) {
          var item = _ref2.item;
          return [_c('tr', [_c('td', [_vm._v("x " + _vm._s(item.quantity))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(item.unitaryPrice) + " € ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(item.totalPrice) + " € ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(item.totalVolume) + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(item.volumePrice) + " €/cm³ ")])])];
        }
      }], null, true)
    })], 1)], 1)], 1);
  }), 1) : _c('v-row', [_c('v-col', {
    staticClass: "pt-8",
    attrs: {
      "align": "center"
    }
  }, [_c('v-alert', {
    attrs: {
      "type": "info",
      "prominent": "",
      "width": "80%",
      "outlined": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('NoPriceAlgorithmFilter')) + " ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
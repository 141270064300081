<template lang="html" src="./partDetailedSuppliersPrices.template.vue"></template>

<style lang="scss" src="./partDetailedSuppliersPrices.scss"></style>

<script>

import { FormulaPricing } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./partDetailedSuppliersPrices.i18n');
export default {
  name: 'PartDetailedSuppliersPrices',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    /**
     * Part object, must have volume and size props
     */
    part: {
      type: Object,
      required: true
    },
    /**
     * Suppliers array
     * Each supplier object should have a 'technologies' attribute
     */
    suppliers: {
      type: Array,
      required: true
    },
    /**
     * Suppliers printers
     * Is used to indicate if the part can be printed in each technology
     */
    printers: {
      type: Array,
      required: true
    }
  },
  watch: {
    part: {
      handler: function() {
        this.updatePartPrices();
      },
      deep: true
    }
  },
  data() {
    return {
      customQuantity: null,
      margin: 12,
      materials: [],
      materialsFilter: [],
      suppliersFilter: [],
      technologies: [],
      technologiesFilter: [],
      quantites: [1,2,3,4,5,10,20,50,100],
      partPrices: [],
      filteredPartPrices: [],
      hideIncompatiblePrinters: true
    };
  },
  created() {
    this.prepareAvailableTechnologiesAndMaterials();
    this.updatePartPrices();
  },
  methods: {
    prepareAvailableTechnologiesAndMaterials() {
      this.materials = [];
      this.technologies = [];
      const tmpMaterialsSet = new Set();
      const tmpTechnologiesSet = new Set();
      for(const supplier of this.suppliers) {
        for(const priceObj of supplier.prices) {
          tmpMaterialsSet.add(priceObj.material);
          tmpTechnologiesSet.add(priceObj.technology);
        }
      }
      for(const material of tmpMaterialsSet) {
        this.materials.push({
          text: this.$t(material),
          value: material
        });
      }
      for(const technology of tmpTechnologiesSet) {
        this.technologies.push({
          text: this.$t(technology),
          value: technology
        });
      }
    },
    updatePartPrices() {
      this.partPrices = [];
      this.filteredPartPrices = [];
      if(!this.part || this.part.volume <= 0) {
        return;
      }
      for(const supplier of this.suppliers) {
        for(const priceObj of supplier.prices) {
          let customPrice = {};
          const prices = [];
          if(this.customQuantity && this.customQuantity > 0) {
            const unitaryPriceCustomQt = ((1 + (this.margin / 100)) * FormulaPricing.getPrice(priceObj.formula,this.part,Number.parseInt(this.customQuantity))).toFixed(2);
            const priceCustomQt = (unitaryPriceCustomQt * this.customQuantity).toFixed(2);
            const totalVolumeCustomQt = this.improveVolumeReadability(this.part.volume * this.customQuantity);
            const volumePriceCustomQt = (unitaryPriceCustomQt / (this.part.volume / 1000)).toFixed(2);
            customPrice = {
              'unitaryPrice': unitaryPriceCustomQt,
              'totalPrice': priceCustomQt,
              'totalVolume': totalVolumeCustomQt,
              'volumePrice': volumePriceCustomQt
            };
          } else {
            customPrice = {
              'unitaryPrice': ' - ',
              'totalPrice': ' - ',
              'totalVolume': ' - cm³',
              'volumePrice': ' - '
            };
          }
          for(const quantity of this.quantites) {
            const unitaryPrice = ((1 + (this.margin / 100)) * FormulaPricing.getPrice(priceObj.formula, this.part, quantity)).toFixed(2);
            const price = (unitaryPrice * quantity).toFixed(2);
            const totalVolume = this.improveVolumeReadability(this.part.volume * quantity);
            const volumePrice = (unitaryPrice / (this.part.volume / 1000)).toFixed(2);
            prices.push({
              'quantity': quantity,
              'unitaryPrice': unitaryPrice,
              'totalPrice': price,
              'totalVolume': totalVolume,
              'volumePrice': volumePrice
            });
          }
          this.partPrices.push({
            supplier: {
              name: supplier.name,
              _id: supplier._id
            },
            technology: priceObj.technology,
            material: priceObj.material,
            customPrice: customPrice,
            prices: prices
          });
        }
      }
      this.updateFilter();
    },
    updatePartCustomPrices() {
    },
    updateFilter() {
      this.filteredPartPrices = [];
      for(const partPrice of this.partPrices) {
        if((!this.suppliersFilter.length || this.suppliersFilter.includes(partPrice.supplier.name))
          && (!this.technologiesFilter.length || this.technologiesFilter.includes(partPrice.technology))
          && (!this.materialsFilter.length || this.materialsFilter.includes(partPrice.material))
          && (!this.hideIncompatiblePrinters || this.hasPrinterLargeEnough(partPrice.supplier._id, partPrice.technology))) {
          this.filteredPartPrices.push(partPrice);
        }
      }
    },
    improveVolumeReadability(volumeInMillimeter) {
      let improvedValue = 0;
      switch (true) {
      case volumeInMillimeter < 1e3:
        improvedValue = `${Math.ceil(volumeInMillimeter)} mm³`;
        break;
      case volumeInMillimeter >= 1e3 && volumeInMillimeter < 1e6:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e3) * 1e2) / 1e2
        } cm³`;
        break;
      case volumeInMillimeter >= 1e6 && volumeInMillimeter < 1e9:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e6) * 1e2) / 1e2
        } dm³`;
        break;
      case volumeInMillimeter >= 1e9:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e9) * 1e2) / 1e2
        } m³`;
        break;
      default:
        improvedValue = 'value';
      }
      return improvedValue;
    },
    hasPrinterLargeEnough(supplierId, technology) {
      return this.printers.some(printer => {
        return printer.owner === supplierId && printer.technology === technology && this.canFit(this.part.size, printer.build_volume);
      });
    },
    canFit(partSize, printerSize) {
      return (partSize.width <= printerSize.width && partSize.depth <= printerSize.depth && partSize.height <= printerSize.height)
        || (partSize.width <= printerSize.width && partSize.height <= printerSize.depth && partSize.depth <= printerSize.height)
        || (partSize.depth <= printerSize.width && partSize.width <= printerSize.depth && partSize.height <= printerSize.height)
        || (partSize.depth <= printerSize.width && partSize.height <= printerSize.depth && partSize.width <= printerSize.height)
        || (partSize.height <= printerSize.width && partSize.width <= printerSize.depth && partSize.depth <= printerSize.height)
        || (partSize.height <= printerSize.width && partSize.depth <= printerSize.depth && partSize.width <= printerSize.height);
    }
  },
};
</script>
